import Vue from "vue";
import editGroupForm from "@/formConfig/editGroupForm";
// eslint-disable-next-line sort-imports
import editAccountingClerkForm from "@/formConfig/editAccountingClerkForm";
import editApplicationForm from "@/formConfig/editApplicationForm";
import editDunningClerkForm from "@/formConfig/editDunningClerkForm";
import editRebateForm from "../../formConfig/editRebateForm";
import editSiteForm from "../../formConfig/editSiteForm";
import editTelesalesForm from "@/formConfig/editTelesalesForm";
import editUserForm from "@/formConfig/editUserForm";
import editZFPayerForm from "@/formConfig/editZFPayerForm";
import editZLPayerForm from "@/formConfig/editZLPayerForm";
import editZRPayerForm from "@/formConfig/editZRPayerForm";

const _ = require("lodash");
const state = {
    "applicationFormSegments": [],
    "applicationKeyTranslations": [],
    "rebatesFormSegments": [],
    "siteFormSegments": [],
    "telesalesFormSegments": [],
    "userFormSegments": [],
    "accountingClerkFormSegments": [],
    "dunningClerkFormSegments": [],
    "zfPayerFormSegments": [],
    "zlPayerFormSegments": [],
    "zrPayerFormSegments": [],
    "globalWarnings": -1,
    "groupFormSegments": []
};
const mutations = {
    SET_FORM_SEGMENTS (currentState, payload) {
        Object.assign(
            currentState[payload.form],
            payload.formSegments
        );
        const globalWarningCount = currentState.applicationFormSegments
            .reduce((sum, x) => sum + x.alert.length, 0);
        Vue.set(currentState, "globalWarnings", globalWarningCount);
        return currentState;
    },
    UPDATE_FORM_SEGMENTS (currentState, segments) {
        currentState.applicationFormSegments = [
            ...currentState.applicationFormSegments,
            ...segments
        ];
    },
    SET_APPLICATION_KEY_TRANSLATIONS (currentState, payload) {
        Vue.set(currentState, "applicationKeyTranslations", payload);
    }
};

const getters = {"getKeyFromTranslation": (currentState) => (translation) => {
    const found = currentState.applicationKeyTranslations
        .find((item) => item?.queryTranslation === translation);
    if (typeof found !== "undefined") {
        return found.key;
    }
    return null;
},
"getTranslationFromKey": (currentState) => (key) => {
    const found = currentState.applicationKeyTranslations.find((item) => item?.key === key);
    if (typeof found !== "undefined") {
        return found.queryTranslation;
    }
    return null;
},

/**
 * Get the fields that are used in the visibilityCondition of a segment
 */
"getVisibilityFields": () => (segment) => {
    if (typeof segment.visibilityCondition !== "undefined") {
        const conditions = segment.visibilityCondition.all || segment.visibilityCondition.any;
        if (conditions) {
            return conditions.filter((condition) => "field" in condition).map((condition) => condition.field);
        }
    }
    return [];
}};

const utils = {

    /**
     * Parse the JSON formSegment into a formSegment that can be used by BaseForm.vue
     * @param formSegment object from JSON file
     * @returns formSegment object that can be used by BaseForm.vue
     */
    "parseFormSegment": (formSegment) => ({
        "title": formSegment.title,
        "hideInSideBar": formSegment.hideInSideBar,
        "id": `${_.snakeCase(formSegment.title)}`,
        "visibilityCondition": formSegment.visibilityCondition,
        // default to true, visibilityCondition will be evaluated later and overrides this
        "visible": true,
        "fields": formSegment.fields
    })
};

const actions = {
    "loadApplicationFormConfig": async ({commit, "getters": { getVisibilityFields }}) => {
        if (state.applicationFormSegments.length === 0) {
            const formSegments = editApplicationForm.formSegments.map((segment) => ({
                ...utils.parseFormSegment(segment),
                "alert": []
            }));

            const translations = [];
            formSegments.forEach((segment) => {
                for (const [key, value] of Object.entries(segment.fields)) {
                    if (typeof value.queryTranslation !== "undefined") {
                        const translationObject = {
                            key,
                            "queryTranslation": value.queryTranslation
                        };
                        translations.push(translationObject);
                    }
                }
                const visibilityFields = getVisibilityFields(segment);
                translations.push(...visibilityFields);
            });

            const payload = {
                "form": "applicationFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
            await commit(
                "SET_APPLICATION_KEY_TRANSLATIONS",
                translations
            );
        }
    },
    "clearSegmentAlerts": async ({commit}) => {
        const formSegments = state.applicationFormSegments;
        for (let i = 0; i < formSegments.length; i++) {
            const segment = formSegments[i];
            segment.alert = [];
        }
        const payload = {
            "form": "applicationFormSegments",
            formSegments
        };
        await commit(
            "SET_FORM_SEGMENTS",
            payload
        );
    },
    "setSegmentAlerts": async ({commit}, data) => {
        const formSegments = state.applicationFormSegments;
        for (let i = 0; i < formSegments.length; i++) {
            const segment = formSegments[i];
            if (segment.id === data.segmentName && !segment.alert.includes(data.fieldName)) {
                segment.alert.push(data.fieldName);
            }
        }
        const payload = {
            "form": "applicationFormSegments",
            formSegments
        };
        await commit(
            "SET_FORM_SEGMENTS",
            payload
        );
    },
    "removeSegmentAlerts": async ({commit}, data) => {
        const formSegments = state.applicationFormSegments;
        for (let i = 0; i < formSegments.length; i++) {
            const segment = formSegments[i];
            if (segment.id === data.segmentName) {
                segment.alert = segment.alert.filter((e) => e !== data.fieldName);
            }
        }
        const payload = {
            "form": "applicationFormSegments",
            formSegments
        };
        await commit(
            "SET_FORM_SEGMENTS",
            payload
        );
    },
    "loadTelesalesConfig": async ({commit}) => {
        if (state.telesalesFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editTelesalesForm.formSegments.length; i++) {
                const currentSegment = editTelesalesForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "telesalesFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadRebateFormConfig": async ({commit}) => {
        if (state.rebatesFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editRebateForm.formSegments.length; i++) {
                const currentSegment = editRebateForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "rebatesFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadSiteFormConfig": async ({commit}) => {
        if (state.siteFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editSiteForm.formSegments.length; i++) {
                const currentSegment = editSiteForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "siteFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadUserFormConfig": async ({commit}) => {
        if (state.userFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editUserForm.formSegments.length; i++) {
                const currentSegment = editUserForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "userFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadGroupFormConfig": async ({commit}) => {
        if (state.groupFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editGroupForm.formSegments.length; i++) {
                const currentSegment = editGroupForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "groupFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadAccountingClerkFormConfig": async ({commit}) => {
        if (state.accountingClerkFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editAccountingClerkForm.formSegments.length; i++) {
                const currentSegment = editAccountingClerkForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "accountingClerkFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadDunningClerkFormConfig": async ({commit}) => {
        if (state.dunningClerkFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editDunningClerkForm.formSegments.length; i++) {
                const currentSegment = editDunningClerkForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "dunningClerkFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadZFPayerFormConfig": async ({commit}) => {
        if (state.zfPayerFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editZFPayerForm.formSegments.length; i++) {
                const currentSegment = editZFPayerForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "zfPayerFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadZLPayerFormConfig": async ({commit}) => {
        if (state.zlPayerFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editZLPayerForm.formSegments.length; i++) {
                const currentSegment = editZLPayerForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "zlPayerFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "loadZRPayerFormConfig": async ({commit}) => {
        if (state.zrPayerFormSegments.length === 0) {
            const formSegments = [];
            for (let i = 0; i < editZRPayerForm.formSegments.length; i++) {
                const currentSegment = editZRPayerForm.formSegments[i];
                const segment = utils.parseFormSegment(currentSegment);
                formSegments.push(segment);
            }
            const payload = {
                "form": "zrPayerFormSegments",
                formSegments
            };
            await commit(
                "SET_FORM_SEGMENTS",
                payload
            );
        }
    },
    "updateApplicationSegments": async ({commit}, data) => {
        await commit(
            "UPDATE_FORM_SEGMENTS",
            data
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    utils,
    actions,
    mutations
};
