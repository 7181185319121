<script>

import FilterManagementDialog from "../views/dialogs/FilterManagementDialog";
import axios from "axios";
import getEnv from "@/utils/env";
import {getFieldsFromTableHeaders} from "../../service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "name": "BaseList",
    "components": {FilterManagementDialog},
    "created" () {
        this.filterText = this.filters.searchFieldQuery ?? "";
        this.options.sortBy = [this.filters.sortField];
        this.statusses = this.storeStatusses;
        this.options.sortDesc = [this.filters.sortOrder === "desc"];
    },
    "apollo": {
        "items": {
            query () {
                const buildSearchQuery = () => this.filters.searchFieldQuery !== null
                    ? `search:
                        {
                            fieldName: ${JSON.stringify(this.searchFields)},
                            searchQuery: "${this.filters.searchFieldQuery}"
                        }`
                    : "";


                const buildPredefinedFilter = () => this.filters.selectedFilter?.filterQuery || "";

                const processFields = (fields) => {
                    const fieldArray = fields.split(" ");
                    const retainedFields = fieldArray.filter((field) => !field.startsWith("individuals"));
                    const removedFields = fieldArray
                        .filter((field) => field.startsWith("individuals"))
                        .map((field) => field.replace("individuals_", "").split("_").slice(1).join("_"));
                    const removedFieldsString = removedFields.length > 0 ? `individuals { role, ${removedFields.join(", ")} }` : "";

                    return { "combinedFields": `${retainedFields.join(" ")} ${removedFieldsString}`.trim() };
                };

                const fields = getFieldsFromTableHeaders(this.tableHeaders);
                const searchGQLQuery = buildSearchQuery();
                const usePredefinedFilter = buildPredefinedFilter();
                const { combinedFields } = fields.includes("individuals") ? processFields(fields) : { "combinedFields": fields };

                return gql`{${this.tableName}: ${this.gqlModel}
                (
                    first: ${this.itemsPerPage},
                    offset: ${this.filters.offset},
                    sort: { fieldName: "${this.filters.sortField}", sortOrder: "${this.filters.sortOrder}" },
                    ${searchGQLQuery},
                    ${usePredefinedFilter}
                ) {
                    totalCount,
                    data {
                        id
                        ${combinedFields}
                    }
                }}`;
            },
            update (data) {
                this.loading = false;
                const tableData = data[this.tableName];
                this.totalCount = tableData.totalCount;
                this.numberOfPages = Math.ceil(this.totalCount / this.itemsPerPage);
                this.processIndividuals(tableData.data);

                return tableData.data;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "computed": {
        filters () {
            return this.$store.state.listFilters[this.tableName];
        },
        storeStatusses () {
            return this.$store.state.statusses;
        },
        telesalesAgencyId () {
            return this.$store.state.telesalesAgencyId.telesalesAgencyId;
        },
        "filtersCurrentPage": {
            "get" () {
                return this.$store.state.listFilters[this.tableName].currentPage;
            },
            "set" (newPageValue) {
                const currentPageValue = this.$store.state.listFilters[this.tableName].currentPage;
                if (newPageValue === currentPageValue) {
                    return;
                }
                this.loading = true;
                this.$store.dispatch(
                    "listFilters/setFilters",
                    {
                        "tableName": this.tableName,
                        "currentPage": newPageValue,
                        "offset": (newPageValue - 1) * this.itemsPerPage
                    }
                );
            }
        }
    },
    "methods": {
        processIndividuals (items) {
            items.forEach((item) => {
                if (!Array.isArray(item.individuals)) {
                    return;
                }

                item.individuals.forEach((individual) => {
                    const rolePrefix = `individuals_${individual.role.toLowerCase()}`;
                    for (const [key, value] of Object.entries(individual)) {
                        if (key !== "role") {
                            item[`${rolePrefix}_${key}`] = value;
                        }
                    }
                });
            });
        },
        goToEdit (item) {
            this.$router.push({
                "name": this.editURL,
                "params": {"id": item.id}
            });
        },
        setSortData (options) {
            const filterParameters = {
                "tableName": this.tableName,
                "offset": 0,
                "currentPage": 1,
                "sortField": options.sortBy[0],
                "sortOrder": options.sortDesc[0] ? "desc" : "asc"
            };

            // Options differ from current settings in the store, so update the store
            if (this.filters.sortField !== filterParameters.sortField ||
                this.filters.sortOrder !== filterParameters.sortOrder) {
                if (options.sortBy.length > 0) {
                    this.loading = true;
                    this.$store.dispatch(
                        "listFilters/setFilters",
                        filterParameters
                    );
                }
            }
        },
        searchData (searchFieldValue) {
            const newSearchValue = searchFieldValue.target.value;
            const currentSearchedValue = this.$store.state.listFilters[this.tableName].searchFieldQuery;
            if (newSearchValue === currentSearchedValue) {
                return;
            }
            this.loading = true;
            this.$store.dispatch(
                "listFilters/setFilters",
                {
                    "tableName": this.tableName,
                    "searchFieldQuery": newSearchValue,
                    "offset": 0,
                    "currentPage": 1
                }
            );
        },
        downloadS3File (filename) {
            const token = window.localStorage.getItem("jwt");
            const serverUrl = getEnv("VUE_APP_SERVER");
            const safeFilename = filename.replace("&", "and");
            const requestUrl = `${serverUrl}/download?filename=${safeFilename}`;

            axios
                .get(requestUrl, {
                    "headers": {
                        "authorization": `Bearer ${token}`
                    },
                    "responseType": "blob"
                })
                .then((response) => {
                    const blob = new Blob([response.data]);
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                });
        }
    },
    data () {
        return {
            "tableName": null,
            "dialog": false,
            "showTextFilter": true,
            "error": null,
            "filterText": "",
            "itemsPerPage": 25,
            "loading": true,
            "numberOfPages": 0,
            "options": {},
            "statusses": [],
            "totalCount": 0
        };
    }
};
</script>
